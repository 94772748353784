/*.App {*/
/*  text-align: center;*/
/*}*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  margin: 0;
  font-family: 'Da', sans-serif;
  background-color: #FFF8E1; /* Light yellow background */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  position: relative;
}

@media (min-width: 1200px) {
  .logo {
    position: absolute;
    top: 2rem; /* Top margin */
    left: 4rem; /* Left margin */
  }

  .logo-svg {
    width: 12vw;
    height: auto;
  }

  .title {
    font-family: 'Darker Grotesque', sans-serif;
    font-size: 20vw;
    font-weight: 500;
    color: #000;
  }

  .subtitle {
    /*margin-top: 20px;*/
  }

  .subtitle-button {
    background-color: white;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 25px 80px;
    font-size: 2vw;
    cursor: default;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
  }

  .confidential {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 1vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 50px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
  }

  .contact-us {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 1vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 50px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
  }
}


@media (max-width: 768px) {
  .logo {
    position: absolute;
    top: 2rem; /* Top margin */
    left: 4rem; /* Left margin */
  }

  .logo-svg {
    width: 30vw;
    height: auto;
  }

  .title {
    font-family: 'Darker Grotesque', sans-serif;
    font-size: 25vw;
    font-weight: 500;
    color: #000;
  }

  .subtitle {
    /*margin-top: 20px;*/
  }

  .subtitle-button {
    background-color: white;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 15px 30px;
    font-size: 5vw;
    cursor: default;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
  }

  .confidential {
    position: absolute;
    bottom: 100px;
    /*right: 20px;*/
    font-size: 3vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 30px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
    margin-top: 20px;
  }

  .contact-us {
    position: absolute;
    bottom: 20px;
    /*left: 20px;*/
    font-size: 3vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 30px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
}

@media (min-width: 729px) and (max-width: 1024px) {
  .logo {
    position: absolute;
    top: 2rem; /* Top margin */
    left: 4rem; /* Left margin */
  }

  .logo-svg {
    width: 20vw;
    height: auto;
  }

  .title {
    font-family: 'Darker Grotesque', sans-serif;
    font-size: 22vw;
    font-weight: 500;
    color: #000;
  }

  .subtitle {
    /*margin-top: 20px;*/
  }

  .subtitle-button {
    background-color: white;
    border: 2px solid #000;
    border-radius: 50px;
    padding: 15px 50px;
    font-size: 3vw;
    cursor: default;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
  }

  .confidential {
    position: absolute;
    bottom: 20px;
    right: 50px;
    font-size: 1.5vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 30px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
    /*margin-top: 20px;*/
  }

  .contact-us {
    position: absolute;
    bottom: 20px;
    left: 50px;
    font-size: 1.5vw;
    color: #000;
    border: 2px solid #000;
    padding: 10px 30px;
    border-radius: 50px;
    font-family: 'Darker Grotesque', sans-serif;
    font-weight: 500;
    /*margin-bottom: 20px;*/
  }
}
